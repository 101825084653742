<template>
  <div class="position-relative pb-1">
    <div v-if="loading" class="loader-overlay">
      <b-spinner style="width: 4rem; height: 4rem" variant="primary" />
    </div>
    <b-card>
      <form
        ref="chatForm"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="submit"
      >
        <validation-observer ref="chatInfo" tag="form">
          <b-row>
            <b-col md="12">
              <b-form-group label="Name En" label-for="Name En">
                <validation-provider
                  #default="{ errors }"
                  name="Name En"
                  rules="required"
                >
                  <b-form-input
                    v-model="value.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Name En"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Name Fr" label-for="Name Fr">
                <validation-provider
                  #default="{ errors }"
                  name="NameFr"
                  rules="required"
                >
                  <b-form-input
                    v-model="value.nameFr"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Name Fr"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <b-button
          id="show-btn"
          @click="submit"
          class="float-right"
          variant="primary"
          :disabled="loading"
          >Save</b-button
        >

        <b-button
          id="cancel-btn"
          @click="close"
          class="float-right mr-1"
          variant="primary"
          >Cancel</b-button
        >
      </form>
    </b-card>
  </div>
</template>
  
  <script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import {
  BFormGroup,
  BFormInput,
  BCard,
  BRow,
  BFormInvalidFeedback,
  BCol,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BSpinner,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import { required } from "@validations";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: {
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    BFormInput,
    TabContent,
    vSelect,
    BFormInvalidFeedback,
    BSpinner,
    BCard,
    BInputGroupAppend,
    BFormCheckbox,
  },
  props: ["value", "action"],
  data() {
    return {
      loading: false,
      formData: null,
      required,
    };
  },
  mounted() {},
  watch: {},
  methods: {
    submit() {
      this.$refs.chatInfo.validate().then((success) => {
        if (success) {
          this.loading = true;
          const formData = new FormData();
          formData.append("name", this.value.name);
          formData.append("nameFr", this.value.nameFr);
          this.$emit("formSubmitted", formData);
        } else {
        }
      });
    },
    close() {
      this.$router.push("/topics");
    },
  },
};
</script>
<style>
.profile-img {
  width: 140px;
  height: 140px;
  border-radius: 1.357rem;
  object-fit: cover;
  cursor: pointer;
  border: groove;
}
</style>
