<template>
  <div class="">
    <TopicForm ref="ChatForm" v-model="formData" @formSubmitted="storeChat"/>
  </div>
</template>

<script>
import TopicForm from "@/views/components/topic/TopicForm";
import TopicService from "@/services/TopicService";

export default {
  name: "CreateChat",
  components: {
    TopicForm
  },
  data() {
    return {
      topicService: new TopicService(),
      formData: {
        // what key i have
      },
    }
  },
  methods: {
    storeChat(e) {
      this.topicService.create(e).then(res => {
        this.$router.push('/topics')
        // window.location.href = 'home'
      }).catch(err => {
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>
</style>
